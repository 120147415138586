import React from 'react';
import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import { StructuredTextDocument } from 'react-datocms';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Typography from '../../../primitives/typography';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import { colors } from '../../../primitives/colors';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import RowSet from '../../../primitives/grid/row-set';
import StyledLink from '../../../primitives/styled-link';
import Link, { getLink } from '../../../primitives/link';
import FadeTransition from '../../../animations/transitions/fade-transition';
import Accordion, { AccordionButton, AccordionIcon, AccordionPanel } from '../../../primitives/accordion';
import RightCaret from '../solutions-carousel/right-caret.svg';
import { FramedDevice, GenericDevice } from '../feature-set';

export type InDepthFeatureSetProps = Queries.InDepthFeatureSetPropsFragment;

const InDepthFeatureSet = ({ richTitle, items, orientation, device, showDeviceFrames }: InDepthFeatureSetProps): JSX.Element => {
    const [value, setValue] = React.useState(0);

    const id = React.useId();

    return (
        <Container
            breakpoints={{ dt: { strength: 'half' } }}
            canStack
            css={{
                marginTop: '80px',
                marginBottom: '80px',
                [breakpoints.mb]: {
                    marginTop: '16px',
                    marginBottom: '16px',
                },
            }}
        >
            <Container
                canStack
                css={{
                    borderRadius: borderRadius.large,
                    overflow: 'hidden',
                    backgroundColor: colors.grey50,
                    [breakpoints.mb]: {
                        backgroundColor: colors.shadesWhite,
                        overflow: 'unset',
                    },
                }}
                breakpoints={{ dt: { strength: 'half', vertical: true } }}
            >
                <Row css={{
                    gridAutoFlow: 'column',
                }}
                >
                    <Col breakpoints={{
                        dt: {
                            span: 6,
                            start: orientation === 'image-on-left' ? 6 : 0,
                        },
                        mb: {
                            span: 4,
                            start: 0,
                        },
                    }}
                    >
                        <div css={{
                            margin: 'auto',
                            width: '100%',
                        }}
                        >
                            <Typography
                                override
                                css={{
                                    paddingBottom: '36px',
                                    [breakpoints.mb]: {
                                        paddingBottom: '0',
                                        textAlign: 'center',
                                    },
                                }}
                                fontWeight="light"
                                fontSize={{
                                    dt: 4053,
                                    mb: 2429,
                                }}
                            >
                                <CustomStructuredTextRenderer data={richTitle as unknown as StructuredTextDocument} />
                            </Typography>
                            <RowSet breakpoints={{ dt: { between: 24 } }}>
                                {items && items.map((card, idx) => (
                                    <div
                                        role="group"
                                        css={{
                                            position: 'relative',
                                            paddingTop: '20px',
                                            paddingLeft: '30px',
                                            paddingBottom: '20px',
                                            paddingRight: '45px',
                                            borderRadius: borderRadius.large,
                                            transition: '0.3s',
                                            userSelect: 'none',
                                            [breakpoints.mb]: {
                                                display: 'none',
                                            },
                                        }}
                                        style={{
                                            border: idx === value ? '1px solid transparent' : `1px solid ${colors.grey100}`,
                                        }}
                                        onMouseEnter={() => {
                                            setValue(idx);
                                        }}
                                    >
                                        {value === idx && (
                                            <motion.div
                                                layoutId={id}
                                                css={{
                                                    position: 'absolute',
                                                    inset: '0',
                                                    boxShadow: '0 24px 54px 0 rgba(0, 0, 0, 0.09)',
                                                    width: 'calc(100% + 2px)',
                                                    left: '-1px',
                                                    top: '-2px',
                                                    height: 'calc(100% + 4px)',
                                                    backgroundColor: colors.shadesWhite,
                                                    [breakpoints.mb]: {
                                                        display: 'none',
                                                    },
                                                }}
                                                style={{
                                                    borderRadius: borderRadius.large,
                                                }}
                                                transition={{
                                                    type: 'spring',
                                                    duration: 0.6,
                                                }}
                                            />
                                        )}
                                        <div css={{
                                            position: 'relative',
                                            zIndex: '1',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                        >
                                            <div>
                                                <Typography
                                                    override
                                                    fontSize={{
                                                        dt: 1822,
                                                        mb: 1627,
                                                    }}
                                                    css={{
                                                        paddingBottom: '8px',
                                                    }}
                                                >
                                                    {card?.title}
                                                </Typography>
                                                <Typography
                                                    override
                                                    fontSize={{
                                                        dt: 1322,
                                                    }}
                                                    css={{
                                                        opacity: 0.8,
                                                    }}
                                                >
                                                    {card?.text}
                                                </Typography>
                                            </div>
                                            <div css={{
                                                paddingLeft: '8px',
                                            }}
                                            >
                                                {(card?.link && card.link.length > 0) && (
                                                    <StyledLink
                                                        to={getLink(card.link[0].link)}
                                                        css={{
                                                            whiteSpace: 'nowrap',
                                                            transition: '0.4s',
                                                            opacity: 0,
                                                            '[role=group]:hover &': {
                                                                opacity: 1,
                                                            },
                                                            [breakpoints.tb]: {
                                                                display: 'none',
                                                            },
                                                        }}
                                                    >
                                                        {card.link[0].title}
                                                    </StyledLink>
                                                )}
                                                <div css={{
                                                    display: 'none',
                                                    [breakpoints.tb]: {
                                                        display: 'block',
                                                    },
                                                }}
                                                >
                                                    <RightCaret />
                                                </div>
                                            </div>
                                        </div>
                                        {(card?.link && card.link.length > 0) && (
                                            <Link
                                                aria-hidden="true"
                                                to={getLink(card.link[0].link)}
                                                css={{
                                                    position: 'absolute',
                                                    width: '100%',
                                                    height: '100%',
                                                    top: '0',
                                                    left: '0',
                                                    zIndex: 1,
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                                <div
                                    css={{
                                        display: 'none',
                                        [breakpoints.mb]: {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '12px',
                                        },
                                    }}
                                >
                                    {items && items.map((card, idx) => (
                                        <Accordion css={{
                                            borderRadius: borderRadius.large,
                                            border: `1px solid ${colors.grey100}`,
                                            boxShadow: '0 24px 54px 0 rgba(0, 0, 0, 0)',
                                            transition: '0.4s',
                                            '&[open]': {
                                                boxShadow: '0 24px 54px 0 rgba(0, 0, 0, 0.09)',
                                                border: `1px solid ${colors.shadesWhite}`,
                                            },
                                        }}
                                        >
                                            <AccordionButton css={{
                                                alignItems: 'flex-start',
                                                padding: '26px',
                                            }}
                                            >
                                                <div>
                                                    <Typography
                                                        override
                                                        fontSize={{
                                                            dt: 1822,
                                                            mb: 1627,
                                                        }}
                                                        css={{
                                                            paddingTop: '8px',
                                                            [breakpoints.mb]: {
                                                                paddingTop: '0',
                                                            },
                                                        }}
                                                    >
                                                        {card?.title}
                                                    </Typography>
                                                    <Typography
                                                        override
                                                        fontSize={{
                                                            dt: 1322,
                                                        }}
                                                        css={{
                                                            opacity: 0.8,
                                                        }}
                                                    >
                                                        {card?.text}
                                                    </Typography>
                                                    {(card?.link && card.link.length > 0) && (
                                                        <StyledLink
                                                            to={getLink(card.link[0].link)}
                                                            css={{
                                                                "[data-state='open'] &": {
                                                                    display: 'inline-block',
                                                                },
                                                                marginTop: '8px',
                                                                display: 'none',
                                                                whiteSpace: 'nowrap',
                                                                transition: '0.4s',
                                                                '[role=group]:hover &': {
                                                                    opacity: 1,
                                                                },
                                                            }}
                                                        >
                                                            {card.link[0].title}
                                                        </StyledLink>
                                                    )}
                                                </div>
                                                <div css={{
                                                    paddingTop: '8px',
                                                }}
                                                >
                                                    <AccordionIcon />
                                                </div>
                                            </AccordionButton>
                                            <AccordionPanel>
                                                <div css={{
                                                    paddingTop: '20px',
                                                    paddingBottom: '20px',
                                                    maxWidth: '92%',
                                                    margin: 'auto',
                                                }}
                                                >
                                                    {showDeviceFrames && (
                                                        <FramedDevice device={device} mediaItem={card?.mediaItem} />
                                                    )}
                                                    {!showDeviceFrames && (
                                                        <GenericDevice device={device} mediaItem={card?.mediaItem} />
                                                    )}
                                                </div>
                                            </AccordionPanel>
                                        </Accordion>
                                    ))}
                                </div>
                            </RowSet>
                        </div>
                    </Col>
                    <Col breakpoints={{
                        dt: {
                            span: 5,
                            start: orientation === 'image-on-left' ? 0 : 7,
                        },
                        mb: {
                            span: 4,
                            start: 0,
                            hidden: true,
                        },
                    }}
                    >
                        <div css={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: (() => {
                                if (device !== 'tablet_horizontal') return 'center';
                                return orientation === 'image-on-left' ? 'flex-end' : 'flex-start';
                            })(),
                            minHeight: 'min(40vw, 680px)',

                        }}
                        >
                            <FadeTransition shouldChange={value.toString()}>
                                {showDeviceFrames && (
                                    <FramedDevice device={device} mediaItem={items[value].mediaItem} />
                                )}
                                {!showDeviceFrames && (
                                    <GenericDevice device={device} mediaItem={items[value].mediaItem} />
                                )}
                            </FadeTransition>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default InDepthFeatureSet;

export const query = graphql`
    fragment InDepthFeatureSetProps on DatoCmsInDepthFeatureSet {
        orientation
        richTitle {
            blocks
            links
            value
        }
        device
        showDeviceFrames
        items {
            title
            text
            mediaItem {
                ...MediaItemProps
            }
            link {
                ...LinkWithTitleProps
            }
        }
    }
`;
